
  import { Component } from 'vue-property-decorator'
  import { LinkedCellOptions } from '@/components/dataTables/cell/index'
  import { plainToInstance } from 'class-transformer'
  import { Stock } from '@/entities/sales'
  import { GForm } from '@/components/forms/GForm'

@Component
  export default class stockDetailCell extends GForm {
  declare options: LinkedCellOptions
  stock = plainToInstance(Stock, {})
  showStock = false

  mounted () {
    console.log(this.id)
  }

  showDetail () {

  }
  }
